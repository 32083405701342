import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Link from "next/link";

const useStyles = makeStyles(() => ({
  link: {
    width: "inherit",
    color: "inherit",
    textDecoration: "none",
    display: "contents",
  },
}));

const CustomLink = ({ children, LinkProps = {} }) => {
  const classes = useStyles();

  const { className, ...otherLinkProps } = LinkProps;

  return (
    <Link className={clsx(classes.link, className)} {...otherLinkProps}>
      {children}
    </Link>
  );
};

export default CustomLink;
