import { makeStyles } from "@material-ui/core";
import { useRouter } from "next/router";

import CustomLink from "@/components/CustomLink";
import ErrorPage from "@/components/ErrorPage";

const useStyles = makeStyles((theme) => ({
  link: {
    margin: "10px 0px",
    color: theme.palette.link.main,
    textDecoration: "underline",
  },
}));

const NotFound = () => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <ErrorPage title="Page not found">
      <CustomLink LinkProps={{ href: "/", replace: true }}>
        <button className={classes.link}>Go to Dashboard</button>
      </CustomLink>

      <button className={classes.link} onClick={() => router.back()}>
        Back to previous
      </button>
    </ErrorPage>
  );
};

export default NotFound;
